@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir/Avenir%20Regular/Avenir%20Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir/Avenir%20Heavy/Avenir%20Heavy.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../fonts/Quicksand/Quicksand-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/manrope-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/manrope-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lexend Deca";
  src: url("../fonts/lexend_deca/LexendDeca-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Lexend Deca";
  src: url("../fonts/lexend_deca/LexendDeca-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sen";
  src: url("../fonts/Sen/static/Sen-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.font-avenir {
  font-family: "Avenir", Arial, sans-serif !important;
}
.font-inter {
  font-family: "Inter", Arial, sans-serif !important;
}
.font-poppins {
  font-family: "Poppins", Arial, sans-serif !important;
}
.font-quicksand {
  font-family: "Quicksand", Arial, sans-serif !important;
}
.font-manrope {
  font-family: "Manrope", Arial, sans-serif !important;
}
.font-lexend-deca {
  font-family: "Lexend Deca", Arial, sans-serif !important;
}
.font-barlow {
  font-family: "Barlow", Arial, sans-serif !important;
}
.font-roboto {
  font-family: "Roboto", Arial, sans-serif !important;
}
.font-sen {
  font-family: "Sen", Arial, sans-serif !important;
}
