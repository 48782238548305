@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;500;700&display=swap");

:root {
  --clr-blue: #062e4b;
  --clr-yellow: #f1c552;
  --clr-light-blue: #eef8fd;
  --clr-white: #ffffff;
  --fs-main: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fs-main);
  padding-right: unset !important;
  overflow: unset !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/***************************/
/* GENERAL TAGS DEFINITION */

a {
  text-decoration: none;
  background-color: transparent;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 2.5rem;
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 1.75rem;
}
h4,
.h4 {
  font-size: 1.5rem;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 0.875rem;
}

header {
  background-color: var(--clr-light-blue);
}

.heading-large-primary {
  color: #273551 !important;
  font-size: 1.625rem !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}
.heading-large-secondary {
  font-family: "Lexend Deca", sans-serif !important;
  color: #818181 !important;
  font-weight: 400 !important;
  font-size: 0.938rem !important;
}
.heading-medium-primary {
  color: #4b4b4b !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}
.listItem-heading-large-primary {
  font-family: "Lexend Deca", sans-serif !important;
  color: #273551 !important;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}
.listItem-heading-large-secondary {
  color: #062e4b !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}
.listItem-heading-medium-primary {
  color: black !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-weight: medium !important;
  line-height: 1.2 !important;
}
.listItem-heading-medium-secondary {
  color: #818181 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  white-space: normal;
  word-wrap: break-word;
}
.listItem-heading-medium-secondary-red {
  color: red !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  white-space: normal;
  word-wrap: break-word;
}
.priceTag-normal-black {
  font-size: 1rem !important;
  color: black !important;
  font-weight: 700 !important;
}
.priceTag-medium-grey {
  font-size: 0.875rem !important;
  color: #3f3d56 !important;
  font-weight: 400 !important;
}
.priceTag-medium-green {
  font-size: 0.875rem !important;
  color: #60b777 !important;
  font-weight: 400 !important;
}
.priceTag-medium-red {
  font-size: 0.875rem !important;
  color: red !important;
  font-weight: 400 !important;
}
.grid-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-primary {
  font-family: "Inter", sans-serif !important;
  background-color: #273551 !important;
  color: white !important;
  min-height: 50px !important;
  width: 100% !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  text-transform: none !important;
  max-width: 35rem;
}

.button-secondary {
  font-family: "Inter", sans-serif !important;
  background-color: #818181 !important;
  color: white !important;
  min-height: 50px !important;
  width: 100% !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  text-transform: none !important;
  max-width: 35rem;
}
.gradient-divider {
  width: 100% !important;
  height: 1px !important;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  margin: 10px 0 !important;
}
.custom-cart-recap-title {
  color: #062e4b !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}
.cart-select-all-button {
  font-family: "Inter", sans-serif !important;
  background-color: #60b777 !important;
  color: white !important;
  max-height: 26px !important;
  width: 50% !important;
  border-radius: 25px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  max-width: 35rem;
}
.return-to-payment-button {
  font-family: "Inter", sans-serif !important;
  background-color: #273551 !important;
  color: white !important;
  max-height: 26px !important;
  border-radius: 25px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  max-width: 35rem;
}
.popup-error-message {
  color: rgb(255, 44, 44) !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}
.popup-button-blue {
  font-family: "Inter", sans-serif !important;
  background-color: #273551 !important;
  color: white !important;
  min-height: 26px !important;
  width: 100% !important;
  border-radius: 25px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem !important;
  font-weight: 700 !important;
  text-transform: none !important;
  max-width: 35rem;
}
.popup-button-blue-2 {
  font-family: "Inter", sans-serif !important;
  color: white !important;
  min-height: 26px !important;
  width: 100% !important;
  border-radius: 25px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem !important;
  font-weight: 700 !important;
  text-transform: none !important;
  max-width: 35rem;
}
.popup-button-blue:disabled {
  background-color: rgba(39, 53, 81, 0.5) !important;
}
.menu-previous-button {
  font-family: "Inter", sans-serif !important;
  background-color: #062e4b !important;
  color: white !important;
  min-height: 26px !important;
  width: 50% !important;
  border-radius: 25px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem !important;
  font-weight: 700 !important;
  text-transform: none !important;
  max-width: 35rem;
}
.tip-button {
  border-radius: 12px !important;
  border: 2px solid black !important;
  background-color: transparent !important;
  color: black !important;
  transition: background-color 0.3s !important;
}
.tip-button-selected {
  border-radius: 12px !important;
  border: 2px solid lightgreen !important;
  background-color: transparent !important;
  color: black !important;
  transition: background-color 0.3s !important;
}

.tip-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.tip-button.selected:hover {
  background-color: lightgreen;
}

.cart-recap-box-item-title {
  font-family: "Inter", sans-serif !important;
  color: black !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.cart-recap-box-item-title-rejected {
  font-family: "Inter", sans-serif !important;
  color: red !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.cart-recap-box-category-item-title {
  font-family: "Inter", sans-serif !important;
  color: black !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.category-caroussel-button-unselected {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: black !important;
  background-color: rgba(217, 217, 217, 0.49) !important;
  border-radius: 20px !important;
  border: 2px solid transparent !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.category-caroussel-button-selected {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: white !important;
  background-color: #273551 !important;
  border-radius: 20px !important;
  border: 2px solid transparent !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.heading-large-primary {
  color: black !important;
  font-size: 1.625rem !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}
.cart-item-preview-title {
  color: black !important;
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 1.2 !important;
}
.personalize-item-modal-title {
  color: black !important;
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 1.2 !important;
}
.blue-round-button {
  font-family: "Inter", sans-serif !important;
  background-color: #273551 !important;
  color: white !important;
  width: 100% !important;
  border-radius: 25px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}
.search-bar {
  background-color: rgb(237, 237, 237) !important;
  border-radius: 25px !important;
  font-family: "Inter", sans-serif !important;
}
/* Custom scrollbar styles for WebKit-based browsers */
.scrollable {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
}

.scrollable::-webkit-scrollbar {
  width: 6px; /* Thin scrollbar width */
}

.scrollable::-webkit-scrollbar-track {
  background: transparent; /* No background for the track */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #888; /* Grey color for the thumb */
  border-radius: 10px; /* Rounded scrollbar thumb */
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker grey when hovered */
}

.scrollable::-webkit-scrollbar-button {
  display: none; /* Hide the top and bottom arrows */
}
.sidebar-buttons {
  align-items: center;
}
.sidebar-buttons:hover {
  background-color: #062e4b !important;
  color: white !important;
  border-radius: 20px;
}
.sidebar-buttons-selected {
  background-color: #062e4b !important;
  color: white !important;
  border-radius: 20px !important;
}
.table-cell-border {
  font-weight: bold;
  border-right: 1px solid #e0e0e0; // Vertical line between cells
  text-align: center;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* Cacher la barre de défilement */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari et Chrome */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer et Edge */
  scrollbar-width: none; /* Firefox */
}

/* Spécifique à l'élément interne de react-horizontal-scrolling-menu */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none; /* Safari et Chrome */
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* Internet Explorer et Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scroll {
  overflow: hidden !important;
}
html,
body {
  height: 100% !important;
  overflow: hidden !important; /* Disable scroll for body */
}

#root {
  height: 100% !important;
  overflow: auto !important; /* Enable scroll for root element */
  overscroll-behavior: contain !important; /* Prevent scroll chaining */
  touch-action: pan-y !important; /* Allow vertical scrolling */
}
.blur {
  filter: blur(5px);
  pointer-events: none; /* Prevents interaction with blurred elements */
}
.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
  line-clamp: 2; /* Standard property for compatibility */
  overflow: hidden;
  text-overflow: ellipsis;
}
